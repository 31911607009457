// 英文
const en={
    lang:"EngLish",
    index1:"DigitalSensation",
    index2:"Satisfy Your Taste Cravings using our Digitals",
    index3:"Eat exactly what you want to eat",
    index4:"Report taste scores",
    index5:"Check taste score",
    index6:"Please enter the restaurant name",
    index7:"Please enter the dish name",
    index8:"Please enter dish quality",
    index9:"About DigitalSenSation",
    index10:"submit",
    index11:"Exampled Food Items with Taste Scores",
    index12:"Contact Us",
    index13:"We love our customers, so feel free to send us an email",
    index14:"please leave us a message",
    index15:"all rights reserved",
    index16:"all rights reserved",
    index17:"Depend on",
    index18:"provide support",
    index19:"Seeking Collaboration",
    index20:"If you are interested in digitalizing the tastes of your food items or helping your customers find the food items they like, contact us for more information about how we can work together to realize our common goal.",
    index22:"We love our customers, so feel free to send us an email",
    index23:"please leave us a message",
    index24:"all rights reserved",
    index25:"Please select a restaurant",
    index26:"Please select a dish",
    index27:"Please select the dish quality",
    index28:"Please enter rating1-7",
    index29:"Taste Scores",
    index30:"Please enter submitter ID",
    index31:"Please enter submitter name",
    index32:"Please enter submitter Email",
    index33:"sourness",
    index34:"sweetness",
    index35:"bitterness",
    index36:"spiciness",
    index37:"saltiness",
    index38:"dishes rating",
    index39:"Submitted successfully"
}
export default en
