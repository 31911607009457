// 中文
const zh={
    lang:"中文",
    index1:"数字感觉",
    index2:"使用我们的数字满足您的味觉渴望",
    index3:"吃你想吃的东西",
    index4:"报告口味分数",
    index5:"检查口味分数",
    index6:"请输入饭馆名称(必填)",
    index7:"请输入菜品名称(必填)",
    index8:"请输入菜品质(必填)",
    index9:"关于数字感觉",
    index10:"提交",
    index11:"带有口味评分的食品示例",
    index12:"联系我们",
    index13:"我们热爱我们的客户，因此请随时给我们发送电子邮件",
    index14:"请给我们留言",
    index15:"版权所有",
    index16:"保留所有权利",
    index17:"由",
    index18:"提供支持",
    index19:"寻求合作",
    index20:"如果您有兴趣将食品的口味数字化或帮助您的客户找到他们喜欢的食品，请联系我们，了解有关我们如何共同努力实现共同目标的更多信息。",
    index22:"我们热爱我们的客户，因此请随时给我们发送电子邮件",
    index23:"请给我们留言",
    index24:"版权所有",
    index25:"请选择餐馆",
    index26:"请选择菜品",
    index27:"请选择菜品品质",
    index28:"请输入评级1-7(必填)",
    index29:"口味评分",
    index30:"请输入提交者身份识别号(必填)",
    index31:"请输入提交者姓名(选填)",
    index32:"请输入提交者电子邮件地址(选填)",
    index33:"酸",
    index34:"甜",
    index35:"苦",
    index36:"辣",
    index37:"咸",
    index38:"菜品评分",
    index39:"提交成功"
}
export default zh