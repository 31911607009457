import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Index', component: () => import('../views/home/index.vue') },
  { path: '/score', name: 'score', component: () => import('../views/home/score.vue') },
  { path: '/contactUs', name: 'contactUs', component: () => import('../views/my/contactUs.vue') }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
