import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import http from '@/api/http'; // 引入 http.js
import '../src/assets/style.css'; // 引入全局样式文件

import i18n from './utils/lang';

Vue.prototype.$http = http; // 将 http.js 挂载到 Vue 原型上，以便全局使用
Vue.use(ElementUI);

// 引入 reset.css
import '@/assets/reset.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
